@import "~@voxel/internet/styles/main.css";

/* @font-face {
  font-family: "Itau Text";
  src: url(~@voxel/internet/styles/fonts/typography/ItauText_W_Rg.woff2) format("woff2"), url(~@voxel/internet/styles//fonts/typography/ItauText_W_Rg.woff) format("woff"), url(~@voxel/internet/styles//fonts/typography/ItauText_W_Rg.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Itau Text";
  src: url(~@voxel/internet/styles//fonts/typography/ItauText_W_Bd.woff2) format("woff2"), url(~@voxel/internet/styles//fonts/typography/ItauText_W_Bd.woff) format("woff"), url(~@voxel/internet/styles//fonts/typography/ItauText_W_Bd.ttf) format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Itau Text";
  src: url(~@voxel/internet/styles//fonts/typography/ItauText_W_XBd.woff2) format("woff2"), url(~@voxel/internet/styles//fonts/typography/ItauText_W_XBd.woff) format("woff"), url(~@voxel/internet/styles//fonts/typography/ItauText_W_XBd.ttf) format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Itau Display";
  src: url(~@voxel/internet/styles//fonts/typography/ItauDisplay_W_Lt.woff2) format("woff2"), url(~@voxel/internet/styles//fonts/typography/ItauDisplay_W_Lt.woff) format("woff"), url(~@voxel/internet/styles//fonts/typography/ItauDisplay_W_Lt.ttf) format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Itau Display";
  src: url(~@voxel/internet/styles//fonts/typography/ItauDisplay_W_Bd.woff2) format("woff2"), url(~@voxel/internet/styles//fonts/typography/ItauDisplay_W_Bd.woff) format("woff"), url(~@voxel/internet/styles//fonts/typography/ItauDisplay_W_Bd.ttf) format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Itau Display";
  src: url(~@voxel/internet/styles//fonts/typography/ItauDisplay_W_XBd.woff2) format("woff2"), url(~@voxel/internet/styles//fonts/typography/ItauDisplay_W_XBd.woff) format("woff"), url(~@voxel/internet/styles//fonts/typography/ItauDisplay_W_XBd.ttf) format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'itau-icon';
  src: url("~@voxel/internet/styles//fonts/itaufonts_master_24px_v1.eot");
  src: url("~@voxel/internet/styles//fonts/itaufonts_master_24px_v1.woff2") format("woff2"), url("~@voxel/internet/styles//fonts/itaufonts_master_24px_v1.woff") format("woff"), url("~@voxel/internet/styles//fonts/itaufonts_master_24px_v1.ttf") format("truetype"), url("~@voxel/internet/styles//fonts/itaufonts_master_24px_v1.eot") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; } */


@import "~@voxel/mobile/styles/main.css";
@import "~@voxel/mobile/styles/fonts.css";

/* html, body { height: 100%; } */
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow: auto;
  background-color: var(--background-color);
}

.noscroll{
  overflow-y: hidden;
}


.msg-error{
  color: red;
  font-size: 13px;
}


/**
 * Tooltip Styles
 */

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  margin-bottom: 5px;
  margin-left: -90px;
  padding: 7px;
  width: 290px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  white-space: pre-line;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}


/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.disabledbutton {
  pointer-events: none;
  touch-action: none;
}

/* Loader */
.lds-roller {

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  z-index: 99999
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
